import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index/Index.vue"),
    meta: {
      title: "歧黄医官",
      keepAlive: true,
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/Error.vue"),
    meta: {
      title: "页面不存在",
    },
  },
  {
    path: "/ffff",
    name: "error",
    component: () => import("@/views/Error.vue"),
    meta: {
      title: "页面不存在",
    },
  },
  {
    path: "/video404",
    name: "video404",
    component: () => import("@/views/consult/Video404.vue"),
    meta: {
      title: "咨询详情",
    },
  },
  {
    path: "/searchDisease",
    name: "searchDisease",
    component: () => import("@/views/search/SearchDisease.vue"),
    meta: {
      title: "选择病症",
      keepAlive: true,
    },
  },
  {
    path: "/searchDoctor",
    name: "searchDoctor",
    component: () => import("@/views/search/SearchDoctor.vue"),
    meta: {
      title: "找医生",
      keepAlive: true,
    },
  },
  {
    path: "/searchDetail",
    name: "searchDetail",
    component: () => import("@/views/search/SearchDetail.vue"),
    meta: {
      title: "找医生",
    },
  },
  {
    path: "/doc",
    name: "doc",
    component: () => import("@/views/doctor/Doctor.vue"),
    meta: {
      title: "医生资料",
    },
  },
  {
    path: "/docFeedback",
    name: "docFeedback",
    component: () => import("@/views/doctor/DocFeedback.vue"),
    meta: {
      title: "医生反馈",
    },
  },
  {
    path: "/docShare",
    name: "docShare",
    component: () => import("@/views/doctor/DocShare.vue"),
    meta: {
      title: "医生分享",
    },
  },
  {
    path: "/docArticleList",
    name: "docArticleList",
    component: () => import("@/views/doctor/DocArticleList.vue"),
    meta: {
      title: "全部患教文章",
    },
  },
  {
    path: "/docArticle",
    name: "docArticleDesc",
    component: () => import("@/views/doctor/DocArticleDesc.vue"),
    meta: {
      title: "文章详情",
    },
  },
  {
    path: "/docRregister",
    name: "docRegister",
    component: () => import("@/views/doctor/DocRegister.vue"),
    meta: {
      title: "邀请使用",
      keepAlive: true,
    },
  },
  {
    path: "/docNoticeList",
    name: "docNoticeList",
    component: () => import("@/views/doctor/DocNoticeList.vue"),
    meta: {
      title: "全部公告文章",
    },
  },
  {
    path: "/docNotice",
    name: "docNoticeDesc",
    component: () => import("@/views/doctor/DocNoticeDesc.vue"),
    meta: {
      title: "公告详情",
    },
  },
  {
    path: "/myDoctor",
    name: "myDoctor",
    component: () => import("@/views/doctor/MyDoctor.vue"),
    meta: {
      title: "我的医生",
    },
  },
  {
    path: "/addFirstPatient",
    name: "addFirstPatient",
    component: () => import("@/views/person/patient/AddFirstPatient.vue"),
    meta: {
      title: "患者报到",
    },
  },
  {
    path: "/addFirstPatient2",
    name: "addFirstPatient2",
    component: () => import("@/views/person/patient/AddFirstPatient2.vue"),
    meta: {
      title: "患者报到",
    },
  },
  {
    path: "/addPatient",
    name: "addPatient",
    component: () => import("@/views/person/patient/AddPatient.vue"),
    meta: {
      title: "患者档案",
    },
  },
  {
    path: "/editPatient",
    name: "editPatient",
    component: () => import("@/views/person/patient/EditPatient.vue"),
    meta: {
      title: "患者档案",
    },
  },
  {
    path: "/paList",
    name: "patientList",
    component: () => import("@/views/person/patient/PatientList.vue"),
    meta: {
      title: "患者管理",
    },
  },
  {
    path: "/selectPatient",
    name: "selectPatient",
    component: () => import("@/views/person/patient/SelectPatient.vue"),
    meta: {
      title: "选择患者",
    },
  },
  {
    path: "/registerPatient",
    name: "registerPatient",
    component: () => import("@/views/person/patient/RegisterPatient.vue"),
    meta: {
      title: "邀请用户",
    },
  },
  {
    path: "/personList",
    name: "personList",
    component: () => import("@/views/person/PersonList.vue"),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/editPhone",
    name: "editPhone",
    component: () => import("@/views/person/phone/EditPhone.vue"),
    meta: {
      title: "验证原手机",
    },
  },
  {
    path: "/changePhone",
    name: "changePhone",
    component: () => import("@/views/person/phone/ChangePhone.vue"),
    meta: {
      title: "更换手机号",
    },
  },
  {
    //支付问诊费用
    path: "/consultPay",
    name: "consultPay",
    component: () => import("@/views/consult/ConsultPay.vue"),
    meta: {
      title: "确认服务",
    },
  },
  {
    //支付问诊费用
    path: "/consultList",
    name: "consultList",
    component: () => import("@/views/consult/ConsultList.vue"),
    meta: {
      title: "我的咨询",
    },
  },
  {
    //预问诊
    path: "/preConsult",
    name: "preConsult",
    component: () => import("@/views/consult/PreConsult.vue"),
    meta: {
      title: "咨询详情",
    },
  },
  {
    //等待问诊
    path: "/waitConsult",
    name: "waitConsult",
    component: () => import("@/views/consult/WaitConsult.vue"),
    meta: {
      title: "咨询详情",
    },
  },
  {
    //问诊中
    path: "/consulting",
    name: "consulting",
    component: () => import("@/views/consult/Consulting.vue"),
    meta: {
      title: "咨询详情",
    },
  },
  {
    path: "/addAnswer",
    name: "addAnswer",
    component: () => import("@/views/disease/AddAnswer.vue"),
    meta: {
      title: "问诊单",
    },
  },
  {
    path: "/addVideoAnswer",
    name: "addVideoAnswer",
    component: () => import("@/views/disease/AddVideoAnswer.vue"),
    meta: {
      title: "问诊单",
    },
  },
  {
    path: "/diseaseScan",
    name: "diseaseScan",
    component: () => import("@/views/disease/DiseaseScan.vue"),
    meta: {
      title: "病症自述",
    },
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () => import("@/views/person/UserAgreement.vue"),
    meta: {},
  },
  {
    path: "/diseaseDesc",
    name: "diseaseDesc",
    component: () => import("@/views/disease/DiseaseDesc.vue"),
    meta: {
      title: "患者自诉",
    },
  },
  {
    //治疗方案
    path: "/prescriptionList",
    name: "prescriptionList",
    component: () => import("@/views/prescription/PrescriptionList.vue"),
    meta: {
      title: "治疗方案",
    },
  },
  {
    //处方详情
    path: "/prescriptionDetail",
    name: "prescriptionDetail",
    component: () => import("@/views/prescription/PrescriptionDetail.vue"),
    meta: {
      title: "处方详情",
    },
  },
  {
    //二维码扫码显示处方详情
    path: "/webHybrid/prescriptionBox",
    name: "prescriptionBox",
    component: () => import("@/views/prescription/PrescriptionBox.vue"),
    meta: {
      title: "处方详情",
    },
  },
  {
    //短信短信开方打开的页面
    path: "/note",
    name: "prescriptionNote",
    component: () => import("@/views/prescription/PrescriptionNote.vue"),
    meta: {
      title: "支付",
    },
  },
  {
    //处方取药
    path: "/prescriptionPay",
    name: "prescriptionPay",
    component: () => import("@/views/prescription/PrescriptionPay.vue"),
    meta: {
      title: "取药",
    },
  },
  {
    //养生方详情
    path: "/gaofangDetail",
    name: "gaofangDetail",
    component: () => import("@/views/prescription/GaofangDetail.vue"),
    meta: {
      title: "传统养生方",
    },
  },
  {
    //成药详情
    path: "/medicineDetail",
    name: "medicineDetail",
    component: () => import("@/views/prescription/MedicineDetail.vue"),
    meta: {
      title: "成药详情",
    },
  },
  {
    //地址列表
    path: "/addressList",
    name: "addressList",
    component: () => import("@/views/person/address/AddressList.vue"),
    meta: {
      title: "地址列表",
    },
  },
  {
    //新增地址
    path: "/addAddress",
    name: "addAddress",
    component: () => import("@/views/person/address/AddAddress.vue"),
    meta: {
      title: "新增地址",
    },
  },
  {
    //编辑地址
    path: "/editAddress",
    name: "editAddress",
    component: () => import("@/views/person/address/EditAddress.vue"),
    meta: {
      title: "编辑地址",
    },
  },
  {
    //意见反馈
    path: "/suggestion",
    name: "suggestion",
    component: () => import("@/views/person/Suggestion.vue"),
    meta: {
      title: "意见反馈",
    },
  },
  {
    //药品订单
    path: "/orderList",
    name: "orderList",
    component: () => import("@/views/order/OrderList.vue"),
    meta: {
      title: "药品订单",
      keepAlive: true,
    },
  },
  {
    //订单详情
    path: "/orderDetail",
    name: "orderDetail",
    component: () => import("@/views/order/OrderDetail.vue"),
    meta: {
      title: "订单详情",
    },
  },
  {
    //查看物流
    path: "/orderLogistics",
    name: "orderLogistics",
    component: () => import("@/views/order/OrderLogistics.vue"),
    meta: {
      title: "查看物流",
    },
  },
  {
    path: "/checkstand",
    name: "checkstand",
    component: () => import("@/views/prescription/Checkstand.vue"),
    meta: {
      title: "收银台",
    },
  },
  {
    //
    path: "/friendPay",
    name: "friendPay",
    component: () => import("@/views/prescription/FriendPay.vue"),
    meta: {
      title: "好友代付",
    },
  },
  {
    //体检订单列表
    path: "/examinationList",
    name: "examinationList",
    component: () => import("@/views/examination/ExaminationList.vue"),
    meta: {
      title: "体检订单",
      keepAlive: true,
    },
  },
  {
    //体检支付
    path: "/examinationPay",
    name: "examinationPay",
    component: () => import("@/views/examination/ExaminationPay.vue"),
    meta: {
      title: "提交订单",
    },
  },
  {
    //体检订单详情
    path: "/examinationDetail",
    name: "examinationDetail",
    component: () => import("@/views/examination/ExaminationDetail.vue"),
    meta: {
      title: "订单详情",
    },
  },
  {
    //体检订单分享
    path: "/examinationNote",
    name: "examinationNote",
    component: () => import("@/views/examination/ExaminationNote.vue"),
    meta: {
      title: "体检分享",
    },
  },
  {
    //按方购药
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/buyMedicine/agreement.vue"),
    meta: {
      title: "按方购药",
    },
  },
  {
    //拍方购药
    path: "/submitMedicine",
    name: "submitMedicine",
    component: () => import("@/views/buyMedicine/submitMedicine.vue"),
    meta: {
      title: "拍方购药",
    },
  },
  {
    //对公转账
    path: "/docTransfer",
    name: "docTransfer",
    component: () => import("@/views/doctor/DocTransfer.vue"),
    meta: {
      title: "对公转账填报",
      keepAlive: true,
    },
  },
  {
    path: "/famousDoc",
    name: "famousDoc",
    component: () => import("@/views/hybrid/FamousDoc.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/famousDoc2",
    name: "famousDoc2",
    component: () => import("@/views/hybrid/FamousDoc2.vue"),
    meta: {
      title: "名医工作室",
    },
  },
  // 按方购药详情
  {
    path: "/agreementMedicineInfo",
    name: "agreementMedicineInfo",
    component: () => import("@/views/hybrid/AgreementMedicineInfo.vue"),
    meta: {
      title: "处方详情",
    },
  },
  {
    path: "/conditioning",
    name: "conditioning",
    component: () => import("@/views/hybrid/Conditioning.vue"),
    meta: {
      title: "调理详情",
    },
  },
  {
    path: "/activity1",
    name: "activity1",
    component: () => import("@/views/hybrid/Activity1.vue"),
    meta: {
      title: "调理详情",
    },
  },
  {
    path: "/FamousDoctorStudio",
    name: "FamousDoctorStudio",
    component: () => import("@/views/hybrid/FamousDoctorStudio.vue"),
    meta: {
      title: "专科名医工作室",
    },
  },
  {
    path: "/shareDoc",
    name: "shareDoc",
    component: () => import("@/views/hybrid/ShareDoc.vue"),
    meta: {
      title: "推荐医生领奖励",
    },
  },
  {
    path: "/bookDetails",
    name: "bookDetails",
    component: () => import("@/views/bookDetails/bookDetails.vue"),
    meta: {
      title: "典籍详情",
    },
  },
  {
    path: "/EquityDetails",
    name: "EquityDetails",
    component: () => import("@/views/hybrid/EquityDetails.vue"),
    meta: {
      title: "权益详情",
    },
  },
  {
    path: "/relationList",
    name: "relationList",
    component: () => import("@/views/prescription/RelationList.vue"),
    meta: {
      title: "关联处方",
    },
  },

  {
    path: "/relationList2",
    name: "relationList2",
    component: () => import("@/views/order/RelationList.vue"),
    meta: {
      title: "关联订单",
    },
  },
  {
    path: "/recordList",
    name: "recordList",
    component: () => import("@/views/disease/RecordList.vue"),
    meta: {
      title: "病历列表",
    },
  },
  {
    path: "/physiqueTest",
    name: "physiqueTest",
    component: () => import("@/views/hybrid/PhysiqueTest.vue"),
    meta: {
      title: "体质测评",
    },
  },
  {
    path: "/consultExplain",
    name: "consultExplain",
    component: () => import("@/views/consult/ConsultExplain.vue"),
    meta: {
      title: "诊后病程管理",
    },
  },
  {
    path: "/addSale",
    name: "addSale",
    component: () => import("@/views/person/afterSale/AddSale.vue"),
    meta: {
      title: "申请售后",
    },
  },
  {
    path: "/saleList",
    name: "saleList",
    component: () => import("@/views/person/afterSale/SaleList.vue"),
    meta: {
      title: "售后记录",
    },
  },
  {
    path: "/saleDesc",
    name: "saleDesc",
    component: () => import("@/views/person/afterSale/SaleDesc.vue"),
    meta: {
      title: "售后详情",
    },
  },
  {
    path: "/shareMedicineInfo",
    name: "shareMedicineInfo",
    component: () => import("@/views/prescription/ShareMedicineInfo.vue"),
    meta: {
      title: "处方详情",
    },
  },
  {
    path: "/chooseNum",
    name: "chooseNum",
    component: () => import("@/views/prescription/ChooseNum.vue"),
    meta: {
      title: "选择疗程",
    },
  },
  {
    path: "/videoConsult",
    name: "videoConsult",
    component: () => import("@/views/consult/VideoConsult.vue"),
    meta: {
      title: "视频问诊",
    },
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/person/Message.vue"),
  },
  {
    path: "/usersApp",
    name: "userApp",
    component: () => import("@/views/person/UserApp.vue"),
  },
  {
    path: "/docApp",
    name: "docApp",
    component: () => import("@/views/person/DocApp.vue"),
  },
  {
    path: "/userMini",
    name: "userMini",
    component: () => import("@/views/person/UserMini.vue"),
  },
  {
    path: "/shopMini",
    name: "shopMini",
    component: () => import("@/views/person/ShopMini.vue"),
  },
  {
    path: "/schedulingList",
    name: "schedulingList",
    component: () => import("@/views/doctor/SchedulingList.vue"),
    title: "坐诊详情",
  },
  {
    path: "/schedulingList",
    name: "schedulingList",
    component: () => import("@/views/doctor/SchedulingList.vue"),
    title: "坐诊详情",
  },
  {
    path: "/questionDesc",
    name: "questionDesc",
    component: () => import("@/views/person/QuestionDesc.vue"),
    title: "疑难解答",
  },
  {
    path: "/no_house_doctor",
    name: "no_house_doctor",
    component: () => import("@/views/hybrid/No_house_doctor.vue"),
    title: "邀请入驻",
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/Test.vue"),
  },
  {
    path: "/bug",
    name: "bug",
    component: () => import("@/views/Bug.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: process.env.NODE_ENV === 'production' ? '/dev' : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});


export default router;
