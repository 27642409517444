<template>
  <div>
    <Loading :show="show" />
    <keep-alive>
      <!--router-view组件是一个 functional 组件，渲染路径匹配到的视图组件-->
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<style lang="less">
</style>
<script>
import Vue from "vue";
import Loading from "../src/components/Loading";

export default {
  name: "App",
  data() {
    return {
      transitionName: ""
    };
  },
  computed: {
    show() {
      return this.$store.state.router.routerShow;
    }
  },
  components: {
    Loading
  },
  created() {
    document.body.removeChild(document.getElementById("loader-box"));
  }
};
</script>
<style lang="less">
#app {
  box-sizing: border-box;
  background-color: #ffffff;
}
</style>
