import Vue from 'vue';
import Vuex from 'vuex';
import router from './modules/router';
import consult from './modules/consult';
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  modules: {
    consult,
    router
  },
  strict: debug
});
export default store;
