import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/index';
import axios from '@/utils/default-axios';
import './utils/common';
import {
  wxConfig
} from './utils/wx.js'
import './css/common.less';
import 'vant/lib/icon/local.css';
import { Tree, Drawer } from "element-ui";

// import VConsole from 'vconsole'
// let vConsole = new VConsole();

Vue.use(Tree);
Vue.use(Drawer);
Vue.config.productionTip = false
// window.onpageshow = function (e) {
//   if (e.persisted) {
//     window.location.reload(true)
//   }
// }

let winWidth = document.body.clientWidth;
let size = (winWidth / 750) * 100;
document.documentElement.style.setProperty('font-size', (size < 100 ? size : 100) + "px", 'important');
window.utils.storage.setter('firstUrl',window.location.href,1);
Vue.prototype.$http = axios;
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    let targetPath = router.history.pending.fullPath
    router.replace(targetPath)
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name == 'consultList') {
    store.commit('router/setShow', 1);
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach((to, from) => {
  if (to.name == 'consultList') {
    store.commit('router/setShow', 0);
  }
  //history 模式需要在页面切换时重新配置微信分享
  wxConfig(to)
  
  window.setTimeout(()=>{
    
  },2000)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
