import Vue from 'vue'
import axios from 'axios';
import router from '@/router'
import encryptor from './encryptor';
import {
    Toast
} from 'vant'
Vue.use(Toast)
let api = ""
if (process.env.NODE_ENV !== 'production') {
    // api = '/api';
    api = 'http://q.xlanben.com/';
}else{
    api = window.location.origin+'/'
}
axios.defaults.baseURL = api
axios.defaults.timeout = 100000;
axios.interceptors.request.use(
    (config) => {
        if (config.method == 'get') {
            let random = {
                random: new Date().getTime()
            }
            if (!config.data) {
                config.data = random
            } else {
                config.data.random = new Date().getTime()
            }
        }
        let token = window.utils.storage.getter('headerToken', 2);
        let exposedToken = window.utils.storage.getter('exposedToken',2)
        if (token) {
            let loginInfo = window.utils.storage.getter('loginInfo', 2) || {}
            config.headers.token = token
            config.headers.openid = loginInfo.openid
        }
        let is_key = false
        let arr = ['patient/login/login','patient/login/loginCheck',"patient/platform/sendMsg","patient/platform/checkSendMsg",'patient/weixin/share',"patient/upload/uploadFile","patient/doctor/doctorInfo","patient/doctor/doctorQrcode"]
        
        if (arr.includes(config.url) ) {
            is_key = false
        }
        if (
            config.data && exposedToken && is_key
        ) {
            // 请求体需要使用 token 对称加密
            config.data = encryptor.encryptWithAES({
                text: JSON.stringify(config.data),
                key: exposedToken,
                iv: 'internethospital'
            });

        }
        config.headers['timestamp'] = Date.now();
        let version =   window.utils.storage.getter("version",2)
        if(version){
            config.headers['version'] = version;
        }
       
        return config;
    },
    (error) => Promise.reject(error),
);
axios.interceptors.response.use(
    (response) => {
        if (response.data.code && response.data.code !== 0) {
            if (response.data.message == '无权操作') {
                router.push('/error')
            } else if (response.data.message == 'openid为空,请在微信客户端打开') {
                router.push('/index')
            } else {
                return Promise.reject(response.data);
            }
        } else {
            return response.data;
        }
    },
    (error) => {

        return Promise.reject(error);
    }
);

export default axios;