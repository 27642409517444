<template>
  <van-overlay :show="show">
    <div class="imgLoading">
      <van-loading type="spinner" color="#ffffff" />
    </div>
  </van-overlay>
</template>

<script>
import Vue from "vue";
import { Overlay, Loading } from "vant";
Vue.use(Overlay).use(Loading);
export default {
  name: "Loading",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {}
};
</script>
